.react-datetime-picker__clock.react-datetime-picker__clock--open {
  display: none !important;
}

.react-paginate {
  margin-bottom: 2rem;
  background-color: #edf2f7;
  text-align: center;
}
.react-paginate ul {
  display: inline-block;

  padding: 15px;
  border-radius: 7px;
}

.react-paginate li {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #556cd6;
  margin: 0.3rem;
  border-radius: 9px;
  cursor: pointer;
  font-weight: 500;
  transition: all 200ms ease-in-out;
}
.react-paginate li > a {
  display: inline-flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.react-paginate li:hover {
  background-color: #556cd6;
  color: white;
}
.react-paginate li.active {
  background-color: #556cd6;
  color: white;
}
