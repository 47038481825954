.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: inherit !important;
  outline: none !important;
  background: transparent !important;
}

.slick-prev,
.slick-prev,
.slick-next,
.slick-next {
  margin: 20px !important;
}
